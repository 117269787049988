<template>
    <div class="page-wrap">
        <div class="container page-wrap__container">
            <div class="row">
                <div class="col-md-12">
                    <h1 class="display-4 title-center">Dit hebben wij ontdekt</h1>
                </div>
            </div>

            <div class="space--30"></div>

            <div class="row">
                <div class="col-md-12">

                    <div v-if="loading">
                        <br>
                        <h2 class="check__info--center"><i class="far fa-spinner-third fa-spin"></i></h2>
                    </div>

                    <div v-if="error">
                        <div role="alert" class="alert alert-danger">
                            {{ error }}
                            <router-link to="/">Probeer het opnieuw</router-link>
                            .
                        </div>
                    </div>

                    <div v-if="result">

                        <!-- <div role="alert" class="alert alert-warning" v-if="result.remarks"
                                v-for="remark in result.remarks">
                            {{remark}}
                        </div> -->

                        <div class="row" v-if="result.properties.is_shortened_url === true">
                            <div class="col">
                                <p class="check__info--center">
                                    Als je de link volgt kom je waarschijnlijk hier terecht:
                                </p>

                                <div class="check__title--center check__result">

                                    <div class="lead--centered"
                                         v-if="result.properties.is_sb_safe && result.properties.is_su_safe">
                                        <a class="lead--centered" data-hj-suppress target="_blank" rel="noreferrer"
                                           :href="result.effective_url">{{result.effective_url}} <i
                                                class="far fa-external-link-alt"></i></a>
                                    </div>

                                    <div class="lead--centered"
                                         v-if="!result.properties.is_sb_safe || !result.properties.is_su_safe">
                                        <i class="far fa-exclamation-circle"></i> {{result.effective_url}}
                                    </div>
                                </div>
                                <div class="space space--30"></div>
                                <div style="text-align:center">
                                    <a
                                            v-if="!notice_seen"
                                            data-balloon-visible data-balloon="Klik voor meer informatie"
                                            data-balloon-pos="down"
                                            class="text-white info-circle" href="javascript:void(0)"
                                            data-micromodal-trigger="howToInterpret"
                                            role="button">
                                        <i class="far fa-info-circle"></i> Hoe kun je dit opvatten?
                                    </a>
                                    <a
                                            v-if="notice_seen"
                                            class="text-white info-circle" href="javascript:void(0)"
                                            data-micromodal-trigger="howToInterpret"
                                            role="button">
                                        <i class="far fa-info-circle"></i> Hoe kun je dit opvatten?
                                    </a>
                                    <span v-if="result.properties.is_su_safe">
                                    &nbsp;
                                    <span class="text-white">|</span>&nbsp;&nbsp;<a
                                            class="text-white info-circle" href="javascript:void(0)"
                                            data-micromodal-trigger="reportPhishing"
                                            role="button">
                                    <i class="far fa-exclamation-triangle"></i> Ik denk dat dit phishing is
                                </a>
                                        </span>
                                </div>
                                <div class="space space--20"></div>
                            </div>
                        </div>
                        <modal id="reportPhishing">
                            <template slot="title">
                                <i class="far fa-exclamation-triangle"></i> Rapporteren dat dit phishing is
                            </template>
                            <template slot="body">
                                <p>
                                    Denk je dat dit phishing is? Je kunt het bij ons melden zodat we je
                                    mede-internetgebruikers er voor kunnen waarschuwen. Bedankt namens hen!
                                </p>
                              <div class="form-group">
                                <label for="effectiveURLToReport">De link die je wil melden</label>
                                <select class="form-control" id="effectiveURLToReport" v-model="consumer_report.url">
                                  <option :value="result.effective_url" v-if="result.url !== result.effective_url">{{ result.effective_url }}</option>
                                  <option :value="result.url">{{ result.url }}</option>
                                </select>
                              </div>
                                <div class="form-group">
                                    <label for="consumerReportDescription">Waarom denk je dat dit phishing is?
                                        (Optioneel)</label>
                                    <textarea type="text" :disabled="consumer_report.is_reported" class="form-control"
                                              maxlength="255"
                                              id="consumerReportDescription"
                                              v-model="consumer_report.description"></textarea>
                                    <small class="form-text text-muted">Je hoeft geen beschrijving in te vullen, maar
                                        het kan ons wel helpen je rapport sneller te verwerken.</small>
                                </div>
                                <p class="alert alert-danger" v-if="consumer_report.error">
                                    {{ consumer_report.error }}
                                </p>
                                <p>
                                    <small><i class="fas fa-info-circle text--blue"></i> Goed om te weten: we
                                        controleren eerst of het bij je melding ook daadwerkelijk om phishing gaat. Het
                                        kan dus even duren voordat de site daadwerkelijk is geblokkeerd.
                                    </small>
                                </p>
                            </template>
                            <template slot="footer">
                                <button class="modal__btn modal__btn-success float-right"
                                        v-if="consumer_report.is_reported" disabled>
                                    <i class="fa fa-check"></i> Bedankt, gemeld.
                                </button>
                                <button class="modal__btn modal__btn-primary float-right" v-on:click="reportPhishing()"
                                        v-if="!consumer_report.is_reported">
                                    <span v-if="!consumer_report.is_loading">Phishing melden <i
                                            class="far fa-arrow-right"></i></span>
                                    <span v-if="consumer_report.is_loading"><i class="fa fa-spin fa-spinner-third"></i> Melden...</span>
                                </button>
                            </template>
                        </modal>
                        <modal id="howToInterpret">
                            <template slot="title">
                                <i class="far fa-info-circle"></i> Hoe kun je dit opvatten?
                            </template>
                            <template slot="body">
                                <p>
                                    Op onze website kun je controleren of een linkje <i>waarschijnlijk</i> veilig is. We
                                    werken er samen met onze partners hard aan om zo volledig mogelijk te zijn, en dus
                                    alle phishingsites op onze website te blokkeren.
                                </p>
                                <p>
                                    Maar, net als bijvoorbeeld je antivirusprogramma, kan het gebeuren dat we er een
                                    keertje naast zitten. In ons geval zou dat betekenen dat een link op onze website
                                    veilig lijkt, terwijl het in wezen om een phishingsite gaat.
                                </p>
                                <p>
                                    Daarom moet je zelf ook altijd alert blijven. Met onze website proberen we je te
                                    helpen een goed oordeel te vellen, maar zekerheid kunnen we je niet bieden.
                                </p>
                                <p>
                                    Als je precies wilt weten wat de duimpjes omhoog of omlaag betekenen, kun je op het
                                    (?) icoon achter de tekst klikken. Daar vind je een korte uitleg over wat het
                                    betekent.
                                </p>
                                <p>
                                    Staat er bijvoorbeeld dat de link naar een echte banksite gaat? Dan kun je er wél
                                    vanuit gaan dat de site ook echt veilig is.
                                </p>
                                <p>
                                    <i class="far fa-info-circle text--blue"></i> Wil je extra tips om online veilig te
                                    blijven? Bekijk ook eens
                                    <a href="https://laatjeniethackmaken.nl" target="_blank">laatjeniethackmaken.nl <i
                                            class="far fa-external-link"></i></a> of <a
                                        href="https://veiliginternetten.nl/themes/basisbeveiliging/" target="_blank">veiliginternetten.nl
                                    <i class="far fa-external-link"></i></a>.
                                </p>

                            </template>
                        </modal>

                        <div class="row" v-if="!result.properties.is_shortened_url">
                            <div class="col check__title--center check__result">

                                <div class="lead--centered"
                                     v-if="result.properties.is_sb_safe && result.properties.is_su_safe">
                                    <a class="lead--centered" data-hj-suppress target="_blank" rel="noreferrer"
                                       :href="result.effective_url">{{result.effective_url}} <i
                                            class="far fa-external-link-alt"></i></a>
                                </div>

                                <div class="lead--centered"
                                     v-if="!result.properties.is_sb_safe || !result.properties.is_su_safe">
                                    <i class="far fa-exclamation-circle"></i> {{result.effective_url}}
                                </div>

                                <div class="space space--30"></div>
                                <div style="text-align:center;font-size:1rem;font-weight:normal;">
                                    <a
                                            v-if="!notice_seen"
                                            data-balloon-visible data-balloon="Klik voor meer informatie"
                                            data-balloon-pos="down"
                                            class="text-white info-circle" href="javascript:void(0)"
                                            data-micromodal-trigger="howToInterpret"
                                            role="button">
                                        <i class="far fa-info-circle"></i> Hoe kun je dit opvatten?
                                    </a>
                                    <a
                                            v-if="notice_seen"
                                            class="text-white info-circle" href="javascript:void(0)"
                                            data-micromodal-trigger="howToInterpret"
                                            role="button">
                                        <i class="far fa-info-circle"></i> Hoe kun je dit opvatten?
                                    </a>
                                    <span v-if="result.properties.is_su_safe">
                                    &nbsp;
                                    <span class="text-white">|</span>&nbsp;&nbsp;<a
                                            class="text-white info-circle" href="javascript:void(0)"
                                            data-micromodal-trigger="reportPhishing"
                                            role="button">
                                    <i class="far fa-exclamation-triangle"></i> Ik denk dat dit phishing is
                                </a>
                                        </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="check--container">

            <div class="container-fluid check__circle--container max__width--1300">
                <div class="row" v-if="result && result.remarks && (result.remarks.length > 0)">
                    <div class="col-md-2"></div>
                    <div class="col-md-8">
                        <div class="alert alert-warning" v-for="remark in result.remarks">
                            {{ remark }}
                        </div>
                    </div>
                    <br><br><br>
                </div>

                <div class="row" v-if="result">
                  <div class="col-lg col-sm-12" v-if="result.properties.hasOwnProperty('is_payment_request') && result.properties.is_payment_request">

                    <div class="wrapper">
                      <div class="check__circle check__circle--green">
                        <i class="fas fa-hand-holding-usd"></i>
                      </div>

                      <p class="check__text check__text--green">Betaalverzoek
                         <a href="javascript:void(0)" data-micromodal-trigger="paymentRequestModal"
                           role="button">(?)</a>

                        <br>
                        <small class="check__text--grey" v-if="result.properties.payment_request.status ==='success'">
                          Je betaalt <a href="javascript:void(0)" data-micromodal-trigger="paymentRequestModal"
                                        role="button">aan {{ result.properties.payment_request.data.beneficiary.name }}
                          <i class="far fa-user" v-if="result.properties.payment_request.data.beneficiary.type === 'consumer'"></i>
                          <i class="fas fa-building" v-if="result.properties.payment_request.data.beneficiary.type === 'business'"></i>
                        </a>
                        </small>
                      </p>

                      <modal id="paymentRequestModal" >
                        <template slot="title">
                          <span v-if="result.properties.payment_request.status ==='success'">Betaalverzoek van {{ result.properties.payment_request.data.beneficiary.name }}</span>
                          <span v-if="result.properties.payment_request.status ==='failed'">Betaalverzoek</span>
                        </template>
                        <template slot="body">

                              <div v-if="result.properties.payment_request.status ==='success'">
                              Je hebt een betaalverzoek ontvangen van {{ result.properties.payment_request.data.beneficiary.name }}.
                              <br><br>
                              <span v-if="result.properties.payment_request.data.beneficiary.type === 'business'">
                                Volgens de gegevens van {{ result.properties.payment_request.data.vendor_name }} is dat een bedrijf.
                              </span>
                              <span v-if="result.properties.payment_request.data.beneficiary.type === 'unknown'">
                                We kunnen niet controleren of het een betaalverzoek van een persoon of bedrijf is. Controleer dus goed of de begunstigde overeenkomt
                                met diegene die je denkt te betalen.
                              </span>
                              <span v-if="result.properties.payment_request.data.beneficiary.type === 'consumer'">
                                <i class="far fa-exclamation-circle text-warning"></i> Let op, je betaalt aan een consument. Verwachtte je een bedrijf te betalen? Dan gaat het waarschijnlijk om een nep betaalverzoek.
                              </span>

                                <div class="row mt-4">
                                  <div class="col-md-2 col-sm-2 d-none d-sm-block">
                                    <img src="/img/incognito.svg" alt="Cybercrimineel" class="img-fluid">
                                  </div>
                                  <div class="col-md-10 col-sm-10">We kunnen bevestigen dat dit betaalverzoek is aangemaakt binnen de omgeving van een bank. Maar let wel op, want
                                    cybercriminelen kunnen rekeningen van katvangers gebruiken om geld weg te sluizen.</div>
                                </div>

                              <br><br>
                              </div>
                              <div v-if="result.properties.payment_request.status ==='failed'">
                                Je hebt een betaalverzoek gecheckt bij Checkjelinkje, maar we kunnen je geen gegevens laten zien van
                                de begunstigde.
                                <br><br>
                              </div>
                          <div class="row">
                            <div class="col-md-2 col-sm-2 d-none d-sm-block"><img src="/img/verification.svg" alt="" class="img-fluid"></div>
                            <div class="col-md-10 col-sm-10 col-xs-12">
                              Wees altijd voorzichtig en controleer goed of de begunstigde overeenkomt met wat je verwacht. Twijfel je?
                              Neem zelf contact op met de verzender van het verzoek.
                            </div>
                          </div>

                              <br><br>
                          <div class="row">
                            <div class="col-md-2 col-sm-2 d-none d-sm-block"><img src="/img/time-out.svg" alt="" class="img-fluid"></div>
                            <div class="col-md-10 col-sm-10"> Wordt je onder druk gezet om snel te betalen? Wees dan extra voorzichtig. Phishers passen vaak druk toe
                              om je snel te laten betalen.</div>
                          </div>

                              <br><br>
                              <i class="far fa-exclamation-circle text--blue"></i> Goed om te weten: we slaan de gegevens over een betaalverzoek niet op in onze systemen.
                              <br>
                        </template>
                      </modal>

                    </div>
                  </div>
                    <div class="col-lg col-sm-12" v-if="result.properties.is_bank_url && !result.properties.is_payment_request">

                        <div class="wrapper">
                            <div class="check__circle check__circle--green">
                                <i class="fas fa-shield-check"></i>
                            </div>

                            <p class="check__text check__text--green">De link gaat naar een banksite.
                                <a href="javascript:void(0)" data-micromodal-trigger="bankModal"
                                   role="button">(?)</a>
                            </p>

                            <modal id="bankModal">
                                <template slot="title">
                                    De link gaat naar een banksite.
                                </template>
                                <template slot="body">
                                    Als je dit ziet staan mag je ervan uitgaan dat de link veilig is. We hebben
                                    namelijk geverifieerd dat deze naar de site van een echte Nederlandse bank gaat.
                                </template>
                            </modal>

                        </div>
                    </div>
                    <div class="col-lg col-sm-12" v-if="result.properties.is_su_safe === false">
                        <div class="wrapper">

                            <div class="check__circle check__circle--red">
                                <i class="far fa-exclamation-circle"></i>
                            </div>

                            <p class="check__text check__text--red">
                                De link bevat hoogstwaarschijnlijk <strong>phishing</strong>.
                                <a href="javascript:void(0)" data-micromodal-trigger="phishingModal"
                                   role="button">(?)</a>
                            </p>

                            <modal id="phishingModal">
                                <template slot="title">
                                    De link bevat hoogstwaarschijnlijk phishing
                                </template>
                                <template slot="body">
                                    Als je deze melding krijgt adviseren we je om de link niet te volgen. Een of
                                    meer van onze partners heeft dan expliciet aangegeven dat de link phishing
                                    bevat.
                                </template>
                            </modal>
                        </div>
                    </div>


                    <div class="col-lg col-sm-12 verifiedEntity" v-if="result.properties.hasOwnProperty('verified_entity')">
                        <div class="wrapper">

                            <div class="check__circle check__circle--primary">
                                <span v-if="result.properties.verified_entity.special_category">
                                    <i class="fas fa-piggy-bank" v-if="result.properties.verified_entity.special_category.identifier === 'bank'"></i>

                                    <i class="fas fa-credit-card" v-if="result.properties.verified_entity.special_category.identifier === 'creditcard_provider'"></i>

                                    <i class="fas fa-sack-dollar" v-if="result.properties.verified_entity.special_category.identifier === 'collection_agency'"></i>

                                    <i class="fas fa-university" v-if="result.properties.verified_entity.special_category.identifier === 'government_agency'"></i>

                                    <i class="fas fa-briefcase-medical" v-if="result.properties.verified_entity.special_category.identifier === 'insurer'"></i>

                                    <i class="fas fa-money-bill-wave" v-if="result.properties.verified_entity.special_category.identifier === 'payment_service_provider'"></i>
                                    <i class="fas fa-badge-check" v-if="result.properties.verified_entity.special_category.identifier === 'known_name'"></i>
                                    <i class="fas fa-building" v-else></i>


                                  <!--                                    <span class="fa-stack-1x" v-if="result.properties.verified_entity.special_category.identifier === 'known_name'" style="font-size: 1rem;">-->
<!--                                        <i class="fas fa-star fa-stack-2x"></i>-->
<!--                                        <i class="fas fa-building fa-stack-1x text-primary" style="margin-top: 7.5%"></i>-->
<!--                                    </span>-->
                                </span>
                            </div>

                            <p class="check__text check__text--grey">
                                <span v-if="result.properties.verified_entity.special_category.active">Geverifieerde {{ result.properties.verified_entity.special_category.name }} </span>
                                <span v-else>Geverifieerd bedrijf </span>

<!--                                <a href="javascript:void(0)" data-micromodal-trigger="verifiedModal"-->
<!--                                   role="button">(?)</a>-->
                                  <br>
                                <small class="check__text--grey">
                                    Geverifieerd door <a href="javascript:void(0)" data-micromodal-trigger="verifiedEntityModal"
                                   role="button">{{ result.properties.verified_entity.name }}</a>
                                </small>
                            </p>

                            <modal id="verifiedModal">
                                <template slot="title">
                                    Geverifieerd bedrijf
                                </template>
                                <template slot="body">
                                    Met Checkjelinkje Verified kunnen bedrijven hun gegevens verifiëren zodat wij ze in de checker kunnen laten zien. Zo zie jij
                                  in één oogopslag welk bedrijf er achter een domeinnaam zit. Wel zo'n fijn idee.
                                </template>
                                <template slot="footer">
                                    <a href="https://verified.checkjelinkje.nl/"
                                       target="_blank">
                                        <button class="modal__btn modal__btn-primary float-right">
                                            Meer informatie <i class="far fa-external-link-alt"></i>
                                        </button>
                                    </a>
                                </template>
                            </modal>
                            <modal id="verifiedEntityModal" class="verifiedEntityModal">
                                <template slot="title"><i class="far fa-check"></i> Gecontroleerde gegevens</template>
                                <template slot="body">
                                    <p>
                                      We hebben vastgesteld dat de website die je hebt gecontroleerd eigendom is van {{ result.properties.verified_entity.name }}.
                                    </p>
                                    <table class="table">
                                      <tr>
                                        <th>Bedrijfsnaam</th>
                                        <td>
                                          {{ result.properties.verified_entity.name }}
                                        </td>
                                      </tr>
                                        <tr>
                                            <th>Adres</th>
                                            <td>
                                            {{ result.properties.verified_entity.address.street }} {{ result.properties.verified_entity.address.house_number }}<br>
                                            {{ result.properties.verified_entity.address.postal_code}} {{ result.properties.verified_entity.address.city}}
                                            </td>
                                        </tr>
                                        <tr v-if="result.properties.verified_entity.email_address">
                                            <th>Email</th>
                                            <td>{{ result.properties.verified_entity.email_address }}</td>
                                        </tr>
                                        <tr v-if="result.properties.verified_entity.phone_number">
                                            <th>Telefoon</th>
                                            <td>{{ result.properties.verified_entity.phone_number }}</td>
                                        </tr>
                                        <tr>
                                            <th>KvK nummer</th>
                                            <td>{{ result.properties.verified_entity.coc_number }}</td>
                                        </tr>
                                        <tr v-if="result.properties.verified_entity.special_category.active">
                                            <th>Soort bedrijf</th>
                                            <td>{{ result.properties.verified_entity.special_category.name }}</td>
                                        </tr>
                                      <tr v-if="result.properties.verified_entity.special_category.active">
                                        <th>&nbsp;</th>
                                        <td>{{ result.properties.verified_entity.special_category.description }}</td>
                                      </tr>
                                    </table>
                                </template>
                            </modal>
                        </div>
                    </div>

                    <div class="col-lg col-sm-12" v-if="result.properties.is_tls">
                        <div class="wrapper">

                            <div class="check__circle check__circle--grey">
                                <i class="fal fa-lock"></i>
                            </div>

                            <p class="check__text check__text--grey">
                                De link gebruikt SSL.
                                <a href="javascript:void(0)" data-micromodal-trigger="sslModal"
                                   role="button">(?)</a>
                            </p>

                            <modal id="sslModal">
                                <template slot="title">
                                    De link gebruikt SSL.
                                </template>

                                <template slot="body">
                                    Het slotje in de webadresbalk van je internetbrowser betekent dat je gebruikmaakt
                                    van een versleutelde verbinding. Hierdoor zijn de gegevens die jij verstuurt, zoals
                                    je wachtwoord of creditcardgegevens, beveiligd tussen jouw browser en de server van
                                    de website die je bezoekt. Wanneer je bijvoorbeeld gebruik maakt van open Wifi
                                    kunnen deze gegevens niet zomaar worden onderschept door een hacker.
                                    <br><br>
                                    <i class="far fa-exclamation-circle text--blue"></i> De versleutelde verbinding zegt
                                    niets over de betrouwbaarheid van de website zelf, phishingsites gebruiken
                                    tegenwoordig óók een versleutelde verbinding om je te doen geloven dat alles veilig
                                    is.<br>
                                </template>

                                <template slot="footer">
                                    <a href="https://laatjeniethackmaken.nl/#let-op-het-slotje-maar-vertrouw-er-niet-op"
                                       target="_blank">
                                        <button class="modal__btn modal__btn-primary float-right">
                                            Meer informatie <i class="far fa-external-link-alt"></i>
                                        </button>
                                    </a>
                                </template>
                            </modal>
                        </div>
                    </div>

                    <div class="col-lg col-sm-12">

                        <div class="wrapper" v-if="result.properties.is_sb_safe">

                            <div class="check__circle--transparent"
                                 v-bind:class="{'background--green': result.properties.is_su_safe , 'background--grey': !result.properties.is_su_safe}">
                                <i class="fal fa-thumbs-up"></i>
                            </div>

                            <p class="check__text check__text--grey">
                                Google acht deze link veilig.
                                <a href="javascript:void(0)" data-micromodal-trigger="googleSafeModal"
                                   role="button">(?)</a>
                            </p>

                            <modal id="googleSafeModal">
                                <template slot="title">
                                    Google acht deze link veilig.
                                </template>
                                <template slot="body">
                                    Om je deze gegevens te tonen maken we gebruik van <a
                                        href="https://safebrowsing.google.com/" target="_blank">Google Safe
                                    Browsing</a>.
                                    Zij baseren
                                    hun oordeel op de miljoenen datapunten die zijn hebben over websites,
                                    domeinnamen en
                                    IP-adressen.
                                    <br><br>
                                    Het is echter mogelijk dat een site wordt aangemerkt als onveilig als dit in
                                    wezen
                                    niet zo is. Ga dus altijd van jezelf uit, als je zeker weet dat de site veilig
                                    is
                                    kun je doorgaan. Als je echter al twijfelt over de link is een "onveilig"
                                    predicaat
                                    van Google en goede indicatie om niet verder te gaan.
                                </template>
                            </modal>

                        </div>

                        <div class="wrapper" v-if="result.properties.is_sb_safe === false">

                            <div class="check__circle check__circle--red">
                                <i class="fal fa-thumbs-down"></i>
                            </div>
                            <p class="check__text check__text--red">
                                Google acht deze link <strong>onveilig</strong>
                                <a href="javascript:void(0)" data-micromodal-trigger="googleUnsafeModal"
                                   role="button">(?)</a>
                            </p>
                            <modal id="googleUnsafeModal">
                                <template slot="title">
                                    Google acht deze link onveilig.
                                </template>
                                <template slot="body">
                                    Om je deze gegevens te tonen maken we gebruik van <a
                                        href="https://safebrowsing.google.com/">Google Safe Browsing</a>. Zij
                                    baseren
                                    hun oordeel op de miljoenen datapunten die zijn hebben over websites,
                                    domeinnamen en
                                    IP-adressen.
                                    <br><br>
                                    Het is echter mogelijk dat een site wordt aangemerkt als onveilig als dit in
                                    wezen
                                    niet zo is. Ga dus altijd van jezelf uit, als je zeker weet dat de site veilig
                                    is
                                    kun je doorgaan. Als je echter al twijfelt over de link is een "onveilig"
                                    predicaat
                                    van Google en goede indicatie om niet verder te gaan.
                                </template>
                            </modal>

                        </div>

                    </div>

                    <div class="col-lg col-sm-12" v-if="result.properties.is_no_bank_site_warning">
                        <div class="wrapper">
                            <div class="check__circle check__circle--orange">
                                <i class="fas fa-info"></i>
                            </div>

                            <p class="check__text text-secondary">De link gaat <strong>niet</strong> naar een
                                banksite.
                                <a href="javascript:void(0)" data-micromodal-trigger="noBankSiteModal"
                                   role="button">(?)</a>
                            </p>

                            <modal id="noBankSiteModal">
                                <template slot="title">
                                    De link gaat niet naar een banksite.
                                </template>
                                <template slot="body">
                                    De link die je checkte gaat niet naar een site van een officiële bank, maar we
                                    vermoeden
                                    dat je dat wel verwachtte. Als iemand je een betaalverzoek heeft verzonden is
                                    die waarschijnlijk
                                    nep.<br><br>
                                    Verwachtte je geen banksite? Dan is er niks aan de hand.
                                </template>
                            </modal>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>

    .background--green {
        background: #22923e;
    }

    .background--grey {
        background: #7f8c8d;
    }

    .max__width--1300 {
        max-width: 1300px;
    }

</style>

<script>
  import 'whatwg-fetch';
  import Modal from '../components/Modal.vue';
  import '../config';
  import app_config from '../config';
  import detect from 'detect-browser';
  import uuid from 'uuid';

  export default {
    name: 'Checker',
    components: {Modal},
    data: function() {
      return {
        browser: {},
        notice_seen: true,
        loading: true,
        error: '',
        result: false,
        consumer_report: {
          url: '',
          description: '',
          is_loading: false,
          is_reported: false,
          error: '',
        },
      };
    },
    created: function() {
      this.browser = detect.detect();
      this.checkIfNoticeIsSeen();
      this.check();
    },
    beforeDestroy: function() {
      document.body.style.background = ('');
    },

    methods: {
      checkIfNoticeIsSeen: function() {
        let noticeIsSeen = localStorage.getItem('notice_is_seen');
        this.notice_seen = !!(noticeIsSeen === 'yes');

        localStorage.setItem('notice_is_seen', 'yes');
      },
      check: function() {
        let url = this.$route.params.pathMatch;//.substring(1);
        let self = this;

        let body = {
          'safe_url_request': {
            'url': url,
          },
        };

        fetch(`https://${app_config.su_api_host}/v1-p/check`, {
          method: 'POST',
          body: JSON.stringify(body),
          headers: new Headers({
            'content-type': 'application/json',
            'x-su-client-version': app_config.client_version,
            'x-su-user-platform': `web-${this.browser.name}-${this.browser.version}`,
            'x-su-client-platform': 'checkjelinkje-nl',
            'x-su-request-uuid': uuid.v4(),
          }),
        }).catch(function() {
          self.loading = false;
          self.error = 'Oeps! Het lijkt er op dat er iets mis ging met je internetverbinding. Of we hebben een storing.';
        }).then(function(response) {
          if (response.status === 422) {
            response.json().then(function(json) {
              self.loading = false;
              self.error = json.error.error_description_translated;
            });
            return;
          }

          if (response.status === 200) {
            response.json().then(function(json) {
              self.result = json.data;
              self.loading = false;
              self.consumer_report.url = self.result.effective_url;

              if (self.result.properties.is_sb_safe === false || self.result.properties.is_su_safe === false) {
                document.body.style.background = ('#dc3545 url(../img/icons.svg');
              }

            });
            return;
          }

          self.loading = false;
          self.error = 'Oeps! Er heeft zich een onbekende fout voorgedaan. Wil je het later nog eens proberen?';
        });
      },
      reportPhishing: async function() {
        this.consumer_report.is_loading = true;
        this.consumer_report.error = false;

        let consumerReport = {
          'consumer_report': {
            'url': this.consumer_report.url,
            'description': this.consumer_report.description,
          },
        };

        let reportResponse = await fetch(`https://${app_config.su_api_host}/v2/consumer-apis/consumer-reports`, {
          method: 'POST',
          body: JSON.stringify(consumerReport),
          headers: {
            'content-type': 'application/json',
            'x-su-client-version': app_config.client_version,
            'x-su-user-platform': `web-${this.browser.name}-${this.browser.version}`,
            'x-su-client-platform': 'checkjelinkje-nl',
            'x-su-request-uuid': uuid.v4(),
          },
        });

        if (reportResponse.status !== 200) {
          this.consumer_report.is_loading = false;
          let error = await reportResponse.json();
          this.consumer_report.error = error.error.error_description_translated;
          return;
        }

        this.consumer_report.is_loading = false;
        this.consumer_report.is_reported = true;
      },
    },
  };
</script>

<style scoped>
    .verifiedEntity .check__circle {
        padding-top: 13px;
        position: relative;
    }
    .verifiedEntity .check__circle::after {
        font-family: "Font Awesome 5 Duotone";
        font-weight: 900;
        content: "\f2f7";
        font-size: 0.9rem;
        position: absolute;
        right: 25%;
        bottom: 17.5%;
    }
    .verifiedEntity .check__circle::before {
        font-family: "Font Awesome 5 Duotone";
        font-weight: 900;
        content: "\10f2f7";
        color: rgba(255, 255, 255, 0.7);
        text-shadow: 0 0 5px #004fe0;
        font-size: 0.9rem;
        position: absolute;
        right: 25%;
        bottom: 17.5%;
    }
    #verifiedEntityModal table{
        /*width: 25vw;*/
        /*max-width: 500px;*/
    }

    @media screen and (max-width: 769px) {
        #verifiedEntityModal table{
            width: 40vw;
        }
    }
</style>
